<template>
    <div class="zykt-main p-1 h-100 box-border">
        <el-card class="zy-card ova yes-head h-100">
            <el-card class="zy-card mb-1">
                <div class="d-flex ai-center mb-1">
                    <zy-cascader
                        class="mr-1"
                        ref="zyCascader"
                        expand-trigger="click"
                        :cascaderData="cascaderData"
                        lazy
                        @lazy-load="handleCascaderLazyLoad"
                        @change="handleCascaderChange"
                    ></zy-cascader>
                    <el-input
                        class="mr-1"
                        v-model="room"
                        placeholder="户号"
                        style="width:120px"
                    ></el-input>

                    <el-select
                        class="selectInp"
                        v-model="paySt"
                        placeholder="缴费状态"
                    >
                        <el-option
                            v-for="item in payStOpt"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                    <el-button
                        type="primary"
                        @click="searchTabData"
                        class="mr-1 ml-1"
                    >
                        搜索
                    </el-button>
                    <el-select
                        class="mr-1 selectInp"
                        v-model="devModel.payType"
                        placeholder="计费模式"
                    >
                        <el-option
                            v-for="item in payOpt"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                    <el-select
                        style="width:200px"
                        class="mr-1 selectInp"
                        v-model="devModel.funType"
                        placeholder="运行状态"
                    >
                        <el-option
                            v-for="item in funOpt"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>

                    <el-select
                        class="mr-1 selectInp"
                        v-model="devModel.cycleType"
                        placeholder="上传周期"
                    >
                        <el-option
                            v-for="item in cycleOpt"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>

                    <el-button type="primary" @click="sendout"
                        >发送参数</el-button
                    >
                </div>
                <div class="d-flex ai-center">
                    <el-button type="primary" @click="clearClick"
                        >时间清零</el-button
                    >
                    <el-date-picker
                        class="ml-1"
                        disabled
                        style="width:230px"
                        :clearable="false"
                        v-model="startTime"
                        type="date"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                    -
                    <el-date-picker
                        style="width:230px"
                        class="mr-1"
                        v-model="endTime"
                        type="date"
                        placeholder="选择结算日期"
                        value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                    <el-input
                        v-model="publicCost"
                        class="mr-1"
                        style="width:150px"
                        placeholder="公摊费用"
                    ></el-input>
                    <el-button type="primary" @click="countPay">结算</el-button>
                </div>
            </el-card>

            <el-card
                class="zy-card opt-head ova-head tab-loading"
                v-loading:tab-loading="loading"
                element-loading-text="数据拼命加载中..."
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
            >
                <div class="fr mr-2">
                    <span style="color:red">小提示：</span>
                    <span class="font-14 mr-1">高档：</span>
                    <i
                        class="iconfont icon-gao font-24 mr-1"
                        style="color:green"
                    >
                    </i>
                    <span class="font-14 mr-1">中档：</span>
                    <i
                        class="iconfont icon-zhong font-24 mr-1"
                        style="color:#44AE4C"
                    >
                    </i>
                    <span class="font-14 mr-1">低档：</span>
                    <i
                        class="iconfont icon-di font-24 mr-1"
                        style="color:#89BD3E"
                    >
                    </i>
                    <span class="font-14 mr-1">关闭：</span>
                    <i class="iconfont icon-didang-hui font-24 "> </i>
                </div>
                <zy-table
                    :tbHead="tbHead"
                    :tbBody="tbBody"
                    selection
                    @selection-change="handleSelectionChange"
                >
                    <el-table-column
                        slot="column-before"
                        type="selection"
                        width="40"
                    >
                    </el-table-column>

                    <el-table-column type="expand" slot="column-before">
                        <template slot-scope="props">
                            <el-form
                                label-position="left"
                                inline
                                class="demo-table-expand"
                                label-width="100px"
                            >
                                <el-form-item label="NB序列号：">
                                    <span>{{ props.row.deviceId }}</span>
                                </el-form-item>
                                <el-form-item label="SIM序列号：">
                                    <span>{{ props.row.sim }}</span>
                                </el-form-item>
                                <el-form-item label="设备号：">
                                    <span>{{ props.row.fpId }}</span>
                                </el-form-item>
                                <el-form-item label="SN号：">
                                    <span>{{ props.row.snId }}</span>
                                </el-form-item>
                            </el-form>
                        </template>
                    </el-table-column>

                    <el-table-column slot="column-after" label="操作">
                        <template slot-scope="scope">
                            <el-button
                                size="mini"
                                type="primary"
                                @click="handleEditUserClick(scope.row)"
                                >编辑</el-button
                            >
                        </template>
                    </el-table-column>

                    <template slot="device-gear" slot-scope="scope">
                        <span :type="scope.row.dangWei" class="ta-cente">
                            <i
                                v-if="scope.row.dangWei === '高档'"
                                class="iconfont icon-gao font-24"
                                style="color:green"
                            >
                            </i>
                            <i
                                v-else-if="scope.row.dangWei === '中档'"
                                class="iconfont icon-zhong font-24"
                                style="color:#44AE4C"
                            >
                            </i>
                            <i
                                v-else-if="scope.row.dangWei === '低档'"
                                class="iconfont icon-di font-24"
                                style="color:#89BD3E"
                            >
                            </i>
                            <i v-else class="iconfont icon-didang-hui font-24">
                            </i>
                            <!-- <span>{{ scope.row.dangWei }}</span> -->
                        </span>
                    </template>
                </zy-table>
                <zy-page
                    class="search-site-page mt-1"
                    @current-change="handlePageCurrentChange"
                    @size-change="handlePageSizeChange"
                    :pageModel="pageModel"
                    :total="total"
                    :pageSizes="[15, 20, 50, 300]"
                ></zy-page>
            </el-card>
        </el-card>
        <el-dialog
            title="编辑"
            :visible.sync="editUserDialogVisible"
            width="400px"
        >
            <el-form
                :model="editUserDialogModel"
                class="edit-user-dialog"
                label-width="90px"
                :inline="true"
            >
                <el-form-item label="小区：">
                    <el-input
                        v-model="editUserDialogModel.acname"
                        :disabled="true"
                    ></el-input>
                </el-form-item>
                <el-form-item label="楼栋：">
                    <el-input
                        v-model="editUserDialogModel.building"
                        :disabled="true"
                    ></el-input>
                </el-form-item>
                <el-form-item label="单元：">
                    <el-input
                        v-model="editUserDialogModel.unit"
                        :disabled="true"
                    ></el-input>
                </el-form-item>
                <el-form-item label="户号：">
                    <el-input
                        v-model="editUserDialogModel.room"
                        :disabled="true"
                    ></el-input>
                </el-form-item>
                <el-form-item label="旧设备号：">
                    <el-input
                        v-model="editUserDialogModel.fpId"
                        :disabled="true"
                    ></el-input>
                </el-form-item>

                <el-form-item label="新设备号：">
                    <el-input v-model="editUserDialogModel.newFpId"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editUserDialogVisible = false">
                    取 消
                </el-button>
                <el-button type="primary" @click="handleEditUserEnterClick">
                    确 定
                </el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import cascader from '@/mixins/select'
import ZyTable from '@/components/ZyTable/index'
import ZyPage from '@/mixins/ZyPage'
export default {
    components: { ZyTable },
    mixins: [ZyPage, cascader],
    data() {
        return {
            loading: false,
            room: '',
            // 计费状态
            paySt: '',
            payStOpt: [
                {
                    value: '全部',
                    label: '全部'
                },
                {
                    value: '已缴费',
                    label: '已缴费'
                },
                {
                    value: '未缴费',
                    label: '未缴费'
                },
                {
                    value: '欠费',
                    label: '欠费'
                }
            ],
            // 发送参数Model
            devModel: {},
            // 计费模式
            payOpt: [
                {
                    label: '计费',
                    value: '01'
                },
                {
                    label: '不计费',
                    value: '00'
                }
            ],

            // 运行状态
            funOpt: [
                {
                    label: '自动运行',
                    value: '01'
                },
                {
                    label: '强制关闭',
                    value: '00'
                }
            ],

            // 周期
            cycleOpt: [
                {
                    value: '5',
                    label: '5分钟'
                },
                {
                    value: '30',
                    label: '30分钟'
                },
                {
                    value: '60',
                    label: '60分钟'
                },
                {
                    value: '120',
                    label: '120分钟'
                },
                {
                    value: '180',
                    label: '180分钟'
                },
                {
                    value: '240',
                    label: '240分钟'
                }
            ],

            // picker: [],
            startTime: '',
            endTime: '',

            // 公摊费用
            publicCost: '',
            // 表头
            tbHead: [
                {
                    prop: 'acname',
                    label: '小区'
                },
                {
                    prop: 'building',
                    label: '楼栋',
                    width: 50
                },
                {
                    prop: 'unit',
                    label: '单元',
                    width: 50
                },
                {
                    prop: 'room',
                    label: '户号',
                    width: 60
                },
                {
                    prop: 'totalMoney',
                    label: '余额',
                    width: 100
                },
                // {
                //     prop: '',
                //     label: '缴费',
                //     width: 50
                // },
                {
                    prop: 'jifeiStatus',
                    label: '计费状态'
                },
                {
                    prop: 'status',
                    label: '运行状态'
                },
                {
                    prop: 'dangWei',
                    label: '当前档位',
                    slot: 'device-gear'
                },
                {
                    prop: 'totalDangLiang',
                    label: '总当量',
                    width: 90
                },
                {
                    prop: 'high',
                    label: '高档时间(分)',
                    width: 110
                },
                {
                    prop: 'mid',
                    label: '中档时间(分)',
                    width: 110
                },
                {
                    prop: 'low',
                    label: '低档时间(分)',
                    width: 110
                },
                {
                    prop: 'zhouQi',
                    label: '周期(分)',
                    width: 80
                },
                {
                    prop: 'recordTime',
                    label: '上传时间',
                    sortable: true,
                    width: 160
                }

                // {
                //     prop: 'deviceId',
                //     label: 'NB序列号',
                //     width: 100,
                //     overflow: true
                // },
                // {
                //     prop: '',
                //     label: 'SIM序列号',
                //     width: 110
                // },
                // {
                //     prop: 'fpId',
                //     label: '设备号',
                //     width: 100
                // },
                // {
                //     prop: '',
                //     label: '室外温度',
                //     width: 110
                // }
            ],

            // 内容
            tbBody: [],

            selectRow: [],

            // 编辑用户弹窗
            editUserDialogVisible: false,

            // 编辑用户model
            editUserDialogModel: {}
        }
    },

    created() {
        this.initPeriodPickerModel() //初始化日期
        this.getTabData()
    },

    methods: {
        // 初始化日期
        async initPeriodPickerModel() {
            const res = await this.$api.survey.getCard()
            this.startTime = res.data.lastRecordTime
        },
        // 多选
        handleSelectionChange(row) {
            const that = this
            that.selectRow = row
        },

        // 未勾选警告
        showWarning() {
            if (this.selectRow.length === 0) {
                this.$message({
                    message: '至少勾选一条数据！',
                    type: 'warning'
                })
            }
        },

        // 获取表格数据
        async getTabData() {
            const pageNum = this.pageModel.pagenum.toString()
            const pageSize = this.pageModel.pagesize.toString()

            const res = await this.$api.selectData.getTabData(pageNum, pageSize)
            this.tbBody = res.data.list
            this.total = res.data.total
        },

        // 搜索
        async searchTabData() {
            if (this.cascaderModelArr.length < 4) {
                this.cascaderModel.room = ''
            }
            const par = this.cascaderModel
            par['room'] = this.room
            par['qianFeiStatus'] = this.paySt
            const res = await this.$api.selectData.searchTab(par)
            this.tbBody = res.data.list
            this.total = res.data.total
        },

        // 发送参数
        async sendout() {
            this.showWarning()
            let devArr = []
            let roomArr = []
            this.selectRow.forEach(async item => {
                devArr.push(item.deviceId)
                roomArr.push(item.room)
            })
            var strcookie = document.cookie //获取cookie字符串
            var arrcookie = strcookie.split('; ') //分割
            // 遍历匹配
            for (var i = 0; i < arrcookie.length; i++) {
                var arr = arrcookie[i].split('=')
                if (arr[0] == name) {
                    return arr[1]
                }
            }
            const operator = arr[1]
            const deviceList = devArr
            const roomList = roomArr
            const jiFei = this.devModel.payType || 'FF'
            const zhouQI = this.devModel.cycleType || 'FF'
            const kaiGuan = this.devModel.funType || 'FF'

            const res = await this.$api.device.getDev(
                operator,
                deviceList,
                roomList,
                jiFei,
                zhouQI,
                kaiGuan
            )
            if (res.code == 200) {
                this.$message.success(res.data)
            } else {
                this.$message.error('发送参数失败，请稍后重试!')
            }
            this.getTabData()
        },

        // 点击编辑用户
        handleEditUserClick(row) {
            this.editUserDialogVisible = true
            this.editUserDialogModel = row
        },

        // 确定编辑用户
        async handleEditUserEnterClick() {
            console.log('编辑.....>>', this.editUserDialogModel)

            this.$confirm('是否确认修改信息?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(async () => {
                    var strcookie = document.cookie //获取cookie字符串
                    var arrcookie = strcookie.split('; ') //分割
                    //遍历匹配
                    for (var i = 0; i < arrcookie.length; i++) {
                        var arr = arrcookie[i].split('=')
                        if (arr[0] == name) {
                            return arr[1]
                        }
                    }
                    const FpId = this.editUserDialogModel.fpId
                    const operator = arr[1]
                    const newId = this.editUserDialogModel.newFpId

                    const res = await this.$api.device.editDevId(
                        FpId,
                        operator,
                        newId
                    )

                    if (res.code != 200) {
                        this.$message({
                            type: 'warning',
                            message: res.msg
                        })
                    } else {
                        this.$message({
                            type: 'success',
                            message: res.data
                        })
                    }
                    // 关闭弹窗
                    this.editUserDialogVisible = false
                    // 重新获取表格
                    this.getTabData()
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消修改!'
                    })
                })
        },

        // 时间清零
        clearClick() {
            this.$confirm('是否确认时间清零?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(async () => {
                    this.showWarning()
                    let roomArr = []
                    let deviceArr = []
                    this.selectRow.forEach(item => {
                        roomArr.push(item.room)
                        deviceArr.push(item.deviceId)
                    })
                    var strcookie = document.cookie //获取cookie字符串
                    var arrcookie = strcookie.split('; ') //分割
                    //遍历匹配
                    for (var i = 0; i < arrcookie.length; i++) {
                        var arr = arrcookie[i].split('=')
                        if (arr[0] == name) {
                            return arr[1]
                        }
                    }
                    const operator = arr[1]
                    const roomList = roomArr
                    const deviceList = deviceArr
                    const res = await this.$api.device.timeClear(
                        operator,
                        deviceList,
                        roomList
                    )
                    if (res.code != 200) {
                        this.$message({
                            type: 'warning',
                            message: res.msg
                        })
                    } else {
                        this.$message({
                            type: 'success',
                            message: res.data
                        })
                    }
                    this.getTabData()
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消修改!'
                    })
                })
        },
        // 结算
        async countPay() {
            this.$confirm('是否确认全部结算?   一旦结算结果不可逆！', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                var strcookie = document.cookie //获取cookie字符串
                var arrcookie = strcookie.split('; ') //分割
                //遍历匹配
                for (var i = 0; i < arrcookie.length; i++) {
                    var arr = arrcookie[i].split('=')
                    if (arr[0] == name) {
                        return arr[1]
                    }
                }
                this.loading = true
                setTimeout(async () => {
                    const operator = arr[1]
                    const startTime = this.startTime
                    const jieSuanDay = this.endTime
                    const publicCost = this.publicCost
                    const res = await this.$api.device.countPay(
                        operator,
                        startTime,
                        jieSuanDay,
                        publicCost
                    )
                    this.loading = false
                    if (res.code != 200) {
                        this.$message({
                            type: 'warning',
                            message: res.msg
                        })
                    } else {
                        this.$message({
                            type: 'success',
                            message: res.data
                        })
                    }
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消修改!'
                    })
                })
            }, 200)
        },

        // 分页回调
        getPageList() {
            return this.getTabData()
        }
    }
}
</script>
<style lang="scss" scoped></style>
